define("megaportal-frontend-uq-components/services/auth-manager", ["exports", "megaportal-frontend-uq-components/models/account", "megaportal-frontend-uq-components/authenticators/sso", "megaportal-frontend-uq-components/links", "raven"], function (_exports, _account, _sso, _links, _raven) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    identityService: Ember.inject.service(),
    featureService: Ember.inject.service(),
    favouriteService: Ember.inject.service(),
    router: Ember.inject.service(),
    timetableService: Ember.inject.service(),
    enrolmentType: '',
    showMyCandidature: false,
    userTypeForReporting: null,
    hasCourses: true,
    staffCoursesExist: null,
    accountData: null,
    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),
    HDRStudentProgramCareerCode: 'PGRS',
    init: function init() {
      var _this = this;

      _raven.default.setDataCallback(function (data) {
        data.user = {
          'name': _this.fullName,
          'username': _this.username
        };

        if (typeof data.extra === 'undefined') {
          data.extra = {};
        }

        data.extra['User Type'] = _this.isStaff ? 'Staff' : _this.isStudent ? 'Student' : 'Unknown';
        var enrolmentType = _this.enrolmentType;

        if (!!enrolmentType && enrolmentType.length > 0) {
          data.extra['Enrolment Type'] = enrolmentType;
        }

        return data;
      });

      this._super.apply(this, arguments);
    },
    onAccountDataChange: Ember.observer('accountData', function () {
      this.get('featureService').loadUserFeatures(this.get('username'));
      this.setupUserTypeForReporting();
    }),
    waitForAccount: function waitForAccount() {
      var _this2 = this;

      var poll = function poll(interval, resolve, reloadOnUnAuth) {
        if (!_this2.session.isAuthenticated) {
          if (reloadOnUnAuth) {
            window.location.reload();
          }

          clearInterval(interval);
          resolve(null);
        } else if (_this2.accountData) {
          clearInterval(interval);
          resolve(_this2.accountData);
        } // else, keep waiting.

      };

      return new Promise(function (resolve) {
        var interval = setInterval(function () {
          return poll(interval, resolve, true);
        }, 100);
        poll(interval, resolve, false);
      });
    },
    fullName: Ember.computed('accountData', function () {
      var accountData = this.get('accountData');
      return accountData ? accountData.fullName : '';
    }),
    firstName: Ember.computed('accountData', function () {
      var accountData = this.get('accountData');
      return accountData ? accountData.firstName : '';
    }),
    lastName: Ember.computed('accountData', function () {
      var accountData = this.get('accountData');
      return accountData ? accountData.lastName : '';
    }),
    preferredName: Ember.computed('accountData', function () {
      // 'Preferred name' is sent through as 'firstName' from the backend
      // Legal first name is called 'givenName' in the backend, but isn't sent through
      var accountData = this.get('accountData');
      return accountData ? accountData.firstName : '';
    }),
    employeeNumber: Ember.computed('accountData', function () {
      var accountData = this.get('accountData');
      return accountData ? accountData.employeeNumber : '';
    }),
    username: Ember.computed('accountData', function () {
      var accountData = this.get('accountData');
      return accountData ? accountData.username : '';
    }),
    emailAddress: Ember.computed('accountData', function () {
      var accountData = this.get('accountData');
      return accountData ? accountData.email ? accountData.email : accountData.office365User : '';
    }),
    idpRoles: Ember.computed('accountData', function () {
      var accountData = this.get('accountData');
      return accountData ? accountData.idpRoles : '';
    }),
    isStudent: Ember.computed('accountData', {
      get: function get(key) {
        var accountData = this.get('accountData');

        if (accountData && accountData.roles.includes('ROLE_STUDENT')) {
          return true;
        }

        return false;
      },
      set: function set(key, value) {
        if (value === true) {
          this.set('accountData.roles', ['ROLE_STUDENT']);
          return true;
        } else {
          var roles = this.accountData.roles;
          var roleIndex = roles.indexOf('ROLE_STUDENT');
          this.set('accountData.roles', roles.slice(0, roleIndex) + roles.slice(roleIndex + 1));
          return false;
        }
      }
    }),
    isStaff: Ember.computed('accountData', {
      get: function get(key) {
        var accountData = this.get('accountData');

        if (accountData && accountData.roles.includes('ROLE_STAFF')) {
          return true;
        }

        return false;
      },
      set: function set(key, value) {
        if (value === true) {
          this.set('accountData.roles', ['ROLE_STAFF']);
          return true;
        } else {
          var roles = this.accountData.roles;
          var roleIndex = roles.indexOf('ROLE_STAFF');
          this.set('accountData.roles', roles.slice(0, roleIndex) + roles.slice(roleIndex + 1));
          return false;
        }
      }
    }),
    isAlumni: Ember.computed('accountData', function () {
      var accountData = this.get('accountData');

      if (accountData && accountData.idpRoles.includes('AlumniStudent')) {
        return true;
      } else {
        return false;
      }
    }),
    mailService: Ember.computed('accountData', function () {
      var accountData = this.get('accountData');

      if (accountData && accountData.mailService) {
        return accountData.mailService;
      }

      return false;
    }),
    isExoMailService: Ember.computed('accountData', function () {
      var mailService = this.get('mailService');

      if (mailService === 'EXO') {
        return true;
      }

      return false;
    }),
    isElMailService: Ember.computed('accountData', function () {
      var mailService = this.get('mailService');

      if (mailService === 'EL') {
        return true;
      }

      return false;
    }),
    isExmigMailService: Ember.computed('accountData', function () {
      var mailService = this.get('mailService');

      if (mailService === 'EXMIG') {
        return true;
      }

      return false;
    }),
    isElexoMailService: Ember.computed('accountData', function () {
      var mailService = this.get('mailService');

      if (mailService === 'ELEXO') {
        return true;
      }

      return false;
    }),
    mailboxList: Ember.computed('accountData', function () {
      var mailService = this.get('mailService');
      var mailList = [];
      var elClone = Object.assign({}, _links.Links['email-el']);
      var exoClone = Object.assign({}, _links.Links['email-exo']); // mailbox link handling

      switch (mailService) {
        case "EL":
          // Mailbox is in the alumni tenant.
          // The user is either an:
          //    Alumni who left before the migration  (if AlumniStudent user type) These users left before the migration and have never had a mailbox on the staff system. Do not show the migration status to them.
          //    a not yet migrated student (if Student or QTACStudent user type).
          mailList.push(elClone);
          break;

        case "EXO":
          // Mailbox is in the staff/student tenant.
          mailList.push(exoClone);
          break;

        case "EXMIG":
          // Exchange mailbox migration in progress.
          // This is set when the migration batch starts. It is not based on the planned schedule to give greatest accuracy, especially given the notification to students will specify the day not an exact time. It also accounts for any unexpected changes to the migration times.
          mailList.push(_links.Links['email-migrating']);
          break;

        case "ELEXO":
          // User has both a legacy alumni mailbox and an active student mailbox. The mailbox in the old tenant is not being migrated.
          // User is either:
          //    a returned alumni who is keeping their legacy alumni mailbox
          //    a current student who left UQ after 2009 and has since returned and is a current student who has opted to have two mailboxes.
          elClone.name += " - Alumni";
          exoClone.name += " - Student";
          mailList.push(exoClone, elClone);
          break;

        case "ELDELEXO":
          // Alumni who has left UQ and has a legacy alumni mailbox AND formerly had a mailbox on the staff tenant.
          // These users may be forwarding their student email addresses to personal or to their alumni mailbox or they may not be forwarding at all. The forwarding preference does not affect what my.UQ displays.
          mailList.push(elClone);
          break;

        case "FORWARDING":
          // Alumni who has left UQ and does not have either a student mailbox or a legacy alumni mailbox and is forwarding to their personal email address. They are utilising the DuoCircle service.
          break;

        case "NONE":
          // Alumni who has left UQ and does not have a legacy alumni mailbox and is not forwarding to their personal email address. Their email addresses are invalid and will bounce. They have no UQ email service at all.
          break;
      }

      return mailList;
    }),
    mainMailbox: Ember.computed('mailboxList', function () {
      var all = this.get('mailboxList');
      return all[0];
    }),
    showForwardingPanel: Ember.computed('accountData', function () {
      var alwaysYes = ['EXMIG', 'ELEXO', 'ELDELEXO', 'FORWARDING', 'NONE'];
      var mailService = this.get('mailService');

      if (this.isStaff) {
        return false;
      } else if (mailService === 'EXO') {
        return true;
      }

      if (this.isAlumni) {
        return false;
      } else if (mailService === 'EL') {
        return true;
      }

      return alwaysYes.includes(mailService);
    }),
    mailboxMigrationStatus: Ember.computed('accountData', function () {
      var mailService = this.get("mailService");
      var personalEmail = this.identityService.emailAddress; // Only for students

      if (!this.isStudent) {
        return null;
      }

      var statusMessages = {
        'EL': 'Your student mailbox is pending a migration. Please monitor your student email for updates.',
        'EXO': 'There are no planned migrations of your student mailbox. Any applicable migration is complete.',
        'EXMIG': "Your student mailbox is currently being migrated. An email will be sent to your UQ student mailbox and your personal email address ".concat(personalEmail, " when the migration is complete."),
        'ELEXO': 'You have a student mailbox and an alumni mailbox. There are no planned migrations of your mailboxes.'
      }; // Don't show EL status message to alumni

      if (this.isAlumni) {
        delete statusMessages.EL;
      } // If no applicable message, return null;


      if (!statusMessages.hasOwnProperty(mailService)) {
        return null;
      }

      var message = statusMessages[mailService];
      return {
        type: 'info',
        body: message,
        hideClose: true,
        extraClass: "no-margin"
      };
    }),
    hasRole: function hasRole(roleName) {
      if (!this.accountData || !this.accountData.roles) {
        return false;
      }

      return this.accountData.roles.includes(roleName);
    },
    setupUserTypeForReporting: function setupUserTypeForReporting() {
      var _this3 = this;

      var isStaff = this.isStaff;
      var isStudent = this.isStudent;
      var isAuthenticated = this.isAuthenticated;
      var staffCoursesExist = this.staffCoursesExist; //if not authenticated, we don't consider them a user for reporting, simply return null

      if (!isAuthenticated) {
        return;
      } //if staff return simple role name and check if staff courses exist


      if (isStaff) {
        this.set('userTypeForReporting', 'UQ Staff');
        return _account.default.fetchCipIsCourseContributor().then(function (response) {
          staffCoursesExist = response.isCourseContributor;

          _this3.set("staffCoursesExist", staffCoursesExist);
        });
      } //if is student, a bit more complexity (if not a student or staff, they still end up in here, as we default users to student view)


      if (isStudent) {
        //need to call CIP, determine active program, and career type
        return _account.default.fetchCipPrograms().then(function (studentsPrograms) {
          //otherwise iterate through programs and choose one
          studentsPrograms = studentsPrograms || []; // show my candidature link if has linked candidature

          studentsPrograms.forEach(function (program) {
            if (program.has_linked_candidature) {
              _this3.set('showMyCandidature', true);
            }
          });
          var career,
              filteredStudentsPrograms = studentsPrograms.filter(function (program) {
            return program.enrolment_status_code === 'AC';
          });

          if (filteredStudentsPrograms.find(function (program) {
            return program.career_code === 'UGRD';
          })) {
            _this3.set('enrolmentType', 'UGRD');

            career = 'UG';
          }

          if (filteredStudentsPrograms.find(function (program) {
            return program.career_code === 'PGCW';
          })) {
            _this3.set('enrolmentType', 'PGCW');

            career = 'PG-CW';
          }

          if (filteredStudentsPrograms.find(function (program) {
            return program.career_code === 'PGRS';
          })) {
            _this3.set('enrolmentType', 'PGRS');

            career = 'PG-HDR'; // Check if the user is currently enrolled in courses
            // The user will see different apps depending on whether they're enrolled

            _account.default.fetchCipCoursesCurrent(_this3.get('enrolmentType')).then(function (enrolments) {
              // Filter out HDR courses with no timetable events
              enrolments = enrolments || [];
              var hdrFilteredEnrolments = enrolments.filter(function (enrolment) {
                return ['RSCH9000', 'RSCH9001', 'RSCH9900', 'RSCH9901'].includes(enrolment.course_code) === false;
              });

              _this3.set('hasCourses', hdrFilteredEnrolments.length > 0);
            });
          } //if no career matched, use other.


          if (career == null) {
            _this3.set('userTypeForReporting', 'Other');
          } else {
            _this3.set('userTypeForReporting', career);
          }

          if (_this3.get('enrolmentType') === _this3.HDRStudentProgramCareerCode) {
            // Update favourites/links for HDR students
            _this3.favouriteService.load();
          }
        });
      }
    },
    authenticate: function authenticate() {
      var _this4 = this;

      return this.session.authenticate('authenticator:sso', {}).then(function (data) {
        // Get account info
        if (_this4.get('session.isAuthenticated')) {
          _account.default.fetch().then(function (accountData) {
            if (accountData) {
              // Only set data if it changed (so observers don't fire off needlessly)
              var savedData = _this4.get('accountData');

              if (savedData && savedData.username === accountData.username) {
                return;
              } else {
                _this4.set('accountData', accountData);
              } // Get favourites


              _this4.favouriteService.load();
            }
          });
        } else {
          _this4.set('accountData', null);
        }

        return data;
      }).catch(function (err) {
        if (err.status === 401 || err.status === 403) {
          throw new Error('invalid token');
        } else {
          throw err;
        }
      });
    },
    login: function login() {
      window.location.replace(_sso.default.serverLoginEndpoint);
    }
  });

  _exports.default = _default;
});